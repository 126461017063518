<template>
  <div style="height:100%;overflow-y:scroll;background-color:#0f064c;position:relative">
    <div class="newUserBg" style="background-image: url(img/newUserBg.png);">
      <div class="task_title padding-sm margin-top-xl">
        <img class="task_back" src="img/backw.png" alt="" @click="closePage">
      </div>
      <!-- <div class="new_box display_flex justify-content_flex-justify align-items_center padding-lr-lg" style="background-image: url(img/newUser_button.png);">
            <div v-if="new_user_welfare_list.type == 'photos'" class="newbox_hint" style="background-image: url(img/newbox_hint.png);"></div>
            <P>{{new_user_welfare_list.wel_name}}</P>
            <button v-if="new_user_welfare_list.is_receive == 0" :style="{backgroundImage: 'url(' + 'img/'+(new_user_welfare_list.type == 'photos'?'gocomplete':'gowechat')+'.png' + ')'}" @click="goFinish(new_user_welfare_list.redis_key)"></button>
            <div v-if="new_user_welfare_list.is_receive == 1" class="gethua" style="background-image: url(img/gethua.png);" @click="clickNewbag"></div>
        </div> -->
        <div class="lottery_box" v-if="showLottery">
      <div class="lottery_item_box">
        <div class="lottery_circle"
          :style="{ transform: rotate_deg, transition: rotate_transition, backgroundImage: 'url(img/lottery_circle_new.png)' }">
        </div>
        <div class="lottery_click" @click="start(1)" style="background-image: url(img/lottery_click.png);"></div>
      </div>

    </div>
    <div class="bottom_bg" v-if="!showLottery">
      <div   class="tip_cube" style="background-image: url('img/tip_cube.png');"></div>
      <div class="tip_title" style="background-image: url('img/tip_title.png');"></div>
      <div style="overflow: hidden;background-image: url(img/bottom_bg.png);background-repeat: no-repeat;background-size: 100% 100%;">
        <div class="complete_box">
          <div class="complete_box_item">
            <P class="complete_tip">{{ new_user_welfare_list.wel_name }}</P>
            <div class="btn" v-if="new_user_welfare_list.is_receive == 0"
              :style="{ backgroundImage: 'url(' + 'img/' + (new_user_welfare_list.type == 'photos' ? 'gocomplete' : 'gowechat') + '.png' + ')' }"
              @click="goFinish(new_user_welfare_list.redis_key)"></div>
            <div class="btn" v-if="new_user_welfare_list.is_receive == 1" style="background-image: url(img/gethua.png);"
              @click="clickNewbag"></div>
          </div>

        </div>
      </div>
    </div>
    
    </div>

    <!-- 奖品弹框 -->
    <div class="mask" v-if="showGiftMask">
      <div class="gift_box" style="background-image: url(img/gift_dialog_bg.png);">
        <div style="width:100%;height:100%;position: relative;overflow: hidden;">
          <div class="gift_item_box" style="background-image: url(img/gift_item_bg.png);">
            <img class="gift_pic" :src="gift.img" alt="">
          </div>
          <div class="diamond_gift_name">
            <div class="gift_item_name">{{ gift.name + 'X' + gift.num }}</div>
          </div>
          <div class="ok_btn" style="background-image: url(img/ok.png);" @click="closeGiftMask"></div>
        </div>
      </div>
    </div>
    <!-- 领取失败弹框 -->
    <div class="mask" v-if="showErrorDialog">
      <div class="tip_bg" style="background-image: url(img/tip_bg.png);overflow: hidden;">
        <div class="carrot_tip">{{ tip }}</div>
        <div class="i_know" style="background-image: url(img/ok.png);" @click="closeTipMask"></div>
      </div>
    </div>
  </div>
</template>

<script>
import "@/assets/css/base.css"
import "@/assets/css/index.css"
import { newUser, newUserGetRedPacket, NewUserLottery } from "@/api/api";
import NavBar from '@/components/navBar.vue';
export default {
  name: 'newUser',
  components: {
    NavBar
  },
  data() {
    return {
      new_user_welfare_list: {},
      showLottery: false,
      showErrorDialog: false,
      showGiftMask: false,
      tip: '',
      isAllowClick: true, //是否能够点击
      rotate_deg: 0, //指针旋转的角度
      rotate_transition: " ", //初始化选中的过度属性控制
      gift: {}
    }
  },
  created() {
    let access_token = this.$route.query.access_token
    localStorage.setItem('access_token', access_token)
    this.fetchData()
  },
  methods: {
    start() {
      // if (this.carrot == 0 || this.carrot < 1) {
      //   this.tip = '您的抽奖次数不足~'
      //   this.showErrorDialog = true
      //   return;
      // }
      console.log(this.isAllowClick)
      if (!this.isAllowClick) {
        return
      }

      this.isAllowClick = false
      this.goLottery()
    },
    goLottery(count) {
      // this.isAllowClick = false;
      // this.rotating(45)
      NewUserLottery({}).then(response => {
        console.log(response)
        if (response.code == 10000) {
          this.gift = response.data
          let angle = this.gift.angle
          this.reset()
          this.rotating(angle)
          // this.isAllowClick = true;
        } else {
          this.tip = response.msg
          this.showErrorDialog = true
          this.isAllowClick = false
        }

      },
        error => {
          this.loading = false
          // this.noData = true
          // this.$toast.fail(error)
        }).catch((error) => {
          this.loading = false
          // this.noData = true
          // this.$toast.fail(error)
        })
    },
    rotating(angle) {
      console.log(angle)
      if (!this.isAllowClick) return;
      this.isAllowClick = false;
      this.rotate_transition = "transform 3s ease-in-out";
      var rand_circle = 5; //默认多旋转5圈
      var randomDeg = angle;
      var deg = rand_circle * 360 + randomDeg; //将要旋转的度数  由于是顺时针的转动方向需要用360度来减
      this.rotate_deg = "rotate(" + deg + "deg)";
      var that = this
      that.tt = setTimeout(function () {
        that.showGiftMask = true
      }, 3300)
    },
    reset() {
      this.isAllowClick = true;
      this.rotate_deg = "rotate(" + 0 + "deg)"; //定时器关闭的时候重置角度
      this.rotate_transition = "";
      clearTimeout(this.tt)
    },
    fetchData() {
      newUser().then(response => {
        this.new_user_welfare_list = response.data
        console.log(response)
        if (this.new_user_welfare_list.length == 0) {
          this.closePage()
        } else {
          if (this.new_user_welfare_list.is_draw == 0 && this.new_user_welfare_list.type == 'draw_rotary') {//draw_rotary wechat
            this.showLottery = true
          }
        }
      },
        error => {
          this.$toast.fail(error)
        }).catch((error) => {
          this.$toast.fail(error)
        })
    },
    //新人红包
    clickNewbag() {
      newUserGetRedPacket().then(response => {
        // this.$toast.success(response.msg)
        // setTimeout(() => {
        //   this.closePage()
        // }, 2000);
        this.showLottery = true

      },
        error => {
          // this.$toast.fail(error)
          this.showErrorDialog = true
          this.tip = response.msg
        }).catch((error) => {
          // this.$toast.fail(error)
          this.showErrorDialog = true
          this.tip = response.msg
        })
    },
    closePage() {
      console.log('关闭')
      let params = '调用'
      this.$bridge.callhandler('closePage', params, (data) => {
        // 处理返回数据

      })
    },
    goFinish(key) {
      let params = '调用'
      this.$bridge.callhandler(key, params, (data) => {
        // 处理返回数据
      })
    },
    closeGiftMask() {
      this.showGiftMask = false
      this.closePage()
      this.reset()
    },
    closeTipMask() {
      this.showErrorDialog = false
      this.reset()
    }

  }
}
</script>
